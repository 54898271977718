/* eslint-disable no-prototype-builtins,no-restricted-syntax */
import api from '../../utils/apiFetch';

// const path = 'catalog';

const serialize = (obj) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

export default {
  getFranchisesList: async (params) => {
    const res = await api.get(`franchises?${serialize(params)}`);
    return res;
  },
  getSecondaryMerchants: async (params) => {
    const res = await api.get(`franchises/business?${serialize(params)}`);
    return res;
  },
  createFranchise: async (payload) => {
    const res = await api.post(`franchises`, payload);
    return res;
  },
  getFranchise: async (payload) => {
    const res = await api.get(`franchises/details?${serialize(payload)}`);
    return res;
  },
  getSquareGiftCards: async (payload) => {
    const res = await api.get(`gift-cards/list?${serialize(payload)}`);
    return res;
  },
  createSecondaryMerchant: async (payload) => {
    const res = await api.post(`franchises/business`, payload);
    return res;
  },
};
